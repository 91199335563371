

.btn-google {
    color: #fff;
    background-color: #ea4335;
    border-color: #fff;
  }

  .btn_google {
    color: #fff;
    background-color: #ea4335;
    border-color: #fff;
  }
  .btn:not(:disabled):not(.disabled) {
    cursor: pointer;
  }

  form.user .btn-user {
    font-size: .8rem;
    border-radius: 10rem;
    padding: .75rem 1rem;
  }

  .btn-block {
    display: block;
    width: 100%;
  }

  *, ::after, ::before {
    box-sizing: border-box;
  }

  body {
    font-family: Nunito,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #858796;
    text-align: left;
  }

  .btn-facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff;
  }

  .btn_facebook {
    color: #fff;
    background-color: #3b5998;
    border-color: #fff;
  }
 
  ul {
    list-style: none;
    padding: 0;
  }
  
  ul.pagination li {
    display: inline-block;
    border: 1px solid #e2e2e2;
    display: flex;
    justify-content: center;
  
  }
  
  ul.pagination li a {
    text-decoration: none;
    color: #337ab7;
    font-size: 20px;
  }
  
  /* ul.pagination li.active a {
    color: white;
    
  } */
  ul.pagination li.active {
    background-color: #337ab7;
  }
  
  ul.pagination li a:hover,
  ul.pagination li a.active {
    color: blue;
  }
  
  .pagination-wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  /* .pagination li a {
    position: relative;
    display: block;
    padding: .5rem .75rem;
    margin-left: -1px;
    line-height: 1.25;
    color: #4e73df;
    background-color: #fff;
    border: 1px solid #dddfeb;
  }
  .pagination li.active a{
    color: #fff !important;
    background-color: #4e73df !important;
  } */
  
  
  